<template>
<!-- TODO: Accessbility!! -->
  <form @submit.prevent="formSubmit(handleSubmit)" novalidate class="public-screening-form">
    <transition name="small-slide" mode="out-in">
      <div class="public-screening-form__success" v-if="success" key="success">
        <alert type="success">Your screening request has been submitted. Please check your inbox for an email from our team. Make sure it did not end up in your spam.</alert>
      </div>

      <div v-else key="form">
        <v-text-field
          v-model="$v.form.field_email.$model"
          :error-messages="errorMessages('field_email', 'Email')"
          name="field_email"
          required
          aria-required="true"
          label="Email*"
          @input="delayTouch($v.form.field_email)"
          solo>
        </v-text-field>

        <v-text-field
          v-model="$v.form.field_first_name.$model"
          :error-messages="errorMessages('field_first_name', 'First Name')"
          name="field_first_name"
          required
          aria-required="true"
          label="First Name*"
          solo>
        </v-text-field>
        
        <v-text-field
          v-model="$v.form.field_last_name.$model"
          name="field_last_name"
          :error-messages="errorMessages('field_last_name', 'Last Name')"
          required
          aria-required="true"
          label="Last Name*"
          solo>
        </v-text-field>

        <v-select
          v-model="$v.form.field_org_type.$model"
          :error-messages="errorMessages('field_org_type', 'Organization Type')"
          required
          aria-required="true"
          name="field_org_type"
          :items="orgTypeItems"
          label="Organization Type*"
          solo
          :menu-props="{ maxHeight: 275 }">
        </v-select>

        <v-text-field
          v-if="form.field_org_type === 'Other'"
          v-model="orgTypeOther"
          :error-messages="errorMessages('orgTypeOther', 'Other')"
          required
          aria-required="true"
          name="orgTypeOther"
          label="Other Organization Type*"
          solo>
        </v-text-field>

        <v-text-field
          v-model="$v.form.field_org_name.$model"
          :error-messages="errorMessages('field_org_name', 'Organization Name')"
          required
          aria-required="true"
          name="field_org_name"
          label="Organization Name*"
          solo>
        </v-text-field>

        <v-text-field
          v-model="$v.form.field_org_website.$model"
          :error-messages="errorMessages('field_org_website', 'Organization Website')"
          required
          aria-required="true"
          name="field_org_website"
          label="Organization Website*"
          solo>
        </v-text-field>

        <v-text-field
          v-model="$v.form.field_phone.$model"
          name="field_phone"
          label="Phone"
          type="tel"
          v-mask="`(###) ###-####`"
          solo>
        </v-text-field>

        <v-select
          v-model="$v.form.field_country.$model"
          :error-messages="errorMessages('field_country', 'Country')"
          required
          aria-required="true"
          name="field_country"
          :items="countryItems"
          label="Country*"
          solo
          :menu-props="{ maxHeight: 275 }">
        </v-select>

        <v-text-field
          v-model="$v.form.field_address.$model"
          name="field_address"
          label="Address"
          solo>
        </v-text-field>
        
        <v-text-field
          v-model="$v.form.field_city.$model"
          :error-messages="errorMessages('field_city', 'City')"
          required
          aria-required="true"
          name="field_city"
          label="City*"
          solo>
        </v-text-field>

        <v-text-field
          v-model="$v.form.field_state.$model"
          :error-messages="errorMessages('field_state', 'State / Province / Region')"
          required
          aria-required="true"
          name="field_state"
          label="State / Province / Region*"
          solo>
        </v-text-field>

        <v-text-field
          v-model="$v.form.field_zip_code.$model"
          name="field_zip_code"
          label="Zip Code"
          v-mask="`#####`"
          maxlength="5"
          solo>
        </v-text-field>

        <v-layout wrap>
          <v-flex xs12 sm6>
            <label for="field_screening_goal">
              Primary Goal of Screening
            </label>
            <v-select
              v-model="$v.form.field_screening_goal.$model"
              id="field_screening_goal"
              name="field_screening_goal"
              :items="screeningGoalItems"
              solo
              :menu-props="{ maxHeight: 275 }">
            </v-select>
          </v-flex>

          <v-flex xs12 sm6>
            <label for="field_audience_type">
              Audience Type
            </label>
            <v-select
              v-model="$v.form.field_audience_type.$model"
              id="field_audience_type"
              name="field_audience_type"
              :items="audienceTypeItems"
              solo
              :menu-props="{ maxHeight: 275 }">
            </v-select>
          </v-flex>

          <v-flex xs12 sm6>
            <label for="field_audience_size">
              Estimated Audience Size
            </label>
            <v-select
              v-model="$v.form.field_audience_size.$model"
              id="field_audience_size"
              name="field_audience_size"
              :items="audienceSizeItems"
              solo
              :menu-props="{ maxHeight: 275 }">
            </v-select>
          </v-flex>

          <v-flex xs12 sm6>
            <label for="field_screening_location">
              Screening Location*
            </label>
            <v-text-field
              v-model="$v.form.field_screening_location.$model"
              :error-messages="errorMessages('field_screening_location', 'Screening Location')"
              required
              aria-required="true"
              id="field_screening_location"
              name="field_screening_location"
              hint="i.e. Student Union, Virtual, At Home, Public Park"
              solo>
            </v-text-field>
            <!-- <label for="field_screening_format">
              Screening Link
            </label>
            <v-text-field
              v-model="$v.form.field_screening_format.$model"
              name="field_screening_format"
              solo>
            </v-text-field> -->
          </v-flex>
        </v-layout>

        <label for="preferredDate">
          Preferred Date of Screening*
        </label>
        <v-layout>
          <v-flex xs6>
            <v-text-field
              v-model="preferredDate"
              id="preferredDate"
              :error-messages="errorMessages('preferredDate', 'Date')"
              required
              aria-required="true"
              name="preferredDate"
              v-mask="`##/##/####`"
              permanent-hint
              hint="mm/dd/yyyy"
              label="Date"
              solo>
            </v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-text-field
              v-model="preferredTime"
              name="preferredTime"
              :error-messages="errorMessages('preferredTime', 'Time')"
              required
              aria-required="true"
              v-mask="preferredTime.length > 4 ? '##:##' : '#:##'"
              label="Time"
              permanent-hint
              hint="example 5:30"
              solo>
            </v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-select
              v-model="preferredPeriod"
              name="preferredPeriod"
              :items="['AM', 'PM']"
              solo
              :menu-props="{ maxHeight: 275 }">
            </v-select>
          </v-flex>
        </v-layout>

        <label for="field_screening_venue">
          Screening Venue
        </label>
        <v-select
          v-model="$v.form.field_screening_venue.$model"
          id="field_screening_venue"
          name="field_screening_venue"
          :items="screeningVenueItems"
          solo
          :menu-props="{ maxHeight: 275 }"
          >
        </v-select>

        <v-checkbox
          class="mt-0 mb-1"
          v-model="form.field_hide_screening"
          label="Feature my screening on THE WEST IS BURNING website"
        />

        <label for="field_comments">
          Anything else you would like to us to know?
        </label>
        <v-textarea
          v-model="$v.form.field_comments.$model"
          id="field_comments"
          name="field_comments"
          solo>
        </v-textarea>

        <!-- <v-checkbox
          class="mt-0 mb-1"
          v-model="subscribe"
          label="Email me updates about THE WEST IS BURNING"
        /> -->

        <v-btn
          type="submit"
          :loading="loading"
          color="accent"
          depressed
          class="btn-extra-wide"
          :disabled="!!success">
          Register &amp; Host
        </v-btn>

        <div class="form__alerts">
          <transition name="small-slide" mode="out-in">
            <alert v-if="error" type="error">
              {{ error }}
            </alert>
          </transition>
        </div>

        <recaptcha-policy />
      </div>
    </transition>
  </form>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import subscribeUserMixin from '@/mixins/subscribeUserMixin'
import contentTypeApi from '@/api/contentType'
import { required, email, url } from 'vuelidate/lib/validators'
import { COUNTRIES } from '@/utils/constants'
import { mapActions } from 'vuex'

import pickBy from 'lodash.pickby'
import { mask } from 'vue-the-mask'
import moment from 'moment'

export default {
  name: 'public-screening-form',
  
  mixins: [formMixin, subscribeUserMixin],

  directives: { mask },
  
  data() {
    return {
      subscribe: false,
      countryItems: COUNTRIES,
      dateMask: '##/##/####',
      preferredDate: '',
      preferredTime: '',
      preferredPeriod: 'PM',
      orgTypeOther: '',
      form: {
        field_email: '',
        field_first_name: '',
        field_last_name: '',
        field_org_type: '',
        field_org_name: '',
        field_org_website: '',
        field_phone: '',
        field_address: '',
        field_hide_screening: true,
        field_city: '',
        field_zip_code: '',
        field_country: 'United States',
        field_state: '',
        field_screening_goal: 'Educational (Students)',
        field_audience_type: 'Public',
        field_audience_size: 'Under 50',
        field_screening_format: '',
        field_preferred_date: '',
        field_screening_venue: 'Theater',
        field_screening_location: '',
        field_comments: '',
        field_screening_type: 'Public'
      },
      orgTypeItems: [
        'Business',
        'Cinema',
        'Collaborative',
        'Community Group',
        'Faith Organization',
        'Film Festival',
        'Government Agency',
        'Individual',
        'Non-Profit',
        'Political Party',
        'School',
        'Student Union',
        'Television Station',
        'Other'
      ],
      screeningGoalItems: [
        'Advocacy',
        'Community/NGO',
        'Corporate',
        'Educational (Students)',
        'Educational (Staff)',
        'Fundraising for forest restoration work',
        'Influence government policy',
        'Professional Conference',
        'Public awareness of wildfire causes and solutions',
        'Social',
        'Other'
      ],
      audienceTypeItems: [
        'Public',
        'Employee',
        'Student'
      ],
      audienceSizeItems: [
        'Under 50',
        '50 to 100',
        '100 to 250',
        '250 to 500',
        'Above 500'
      ],
      screeningVenueItems: [
        'Theater',
        'Auditorium',
        'Other'
      ],
      type: 'screening'
    }
  },

  methods: {
    ...mapActions(['generalEvent']),
    getDate() {
      return moment.parseZone(`${this.preferredDate} ${this.preferredTime} ${this.preferredPeriod}`, 'MM/DD/YYYY hh:mm A').format()
    },
    handleInvalidForm () {
      this.$nextTick(() => {
        this.$vuetify.goTo('.v-text-field.error--text', {
          offset: 100
        })
      })
    },
    handleValidSubmission () {
      setTimeout(() => {
        this.$vuetify.goTo('.public-screening-form', {
          offset: 100
        })
      }, 500)
    },
    async handleSubmit() {
      this.error = null
  
      try {
        this.form.field_preferred_date = this.getDate()

        const attrs = pickBy(this.form, (v, k) => {
          return v !== ''
        })
        const orgType = this.orgTypeOther || attrs.field_org_type

        await contentTypeApi.post(this.type, {
          ...attrs,
          title: `Public Screening: ${attrs.field_org_name} - ${attrs.field_screening_location}`,
          field_org_type: orgType,
          field_spam: this.possibleRobot,
          field_hide_screening: !attrs.field_hide_screening
        })

        this.generalEvent({
          category: 'host',
          action: 'submit-success',
          label: 'Public Screening'
        })

        this.handleValidSubmission()

        try {
          this.subscribe && await this.handleSubscribeUser({
            email: this.form.field_email,
            name: `${this.form.field_first_name} ${this.form.field_last_name}` 
          })
        } catch (e) {
          console.error(e)
        }

        this.success = true
        this.resetForm()
      } catch (error) {
        this.success = false
        this.error = 'Uh oh, not sure what happened. Please try again.'
        throw error
      }
    }
  },

  validations() {
    const otherRequired = this.form.field_org_type === 'Other'
      ? required
      : false
    
    return {
      orgTypeOther: { required: otherRequired },
      preferredDate: { required },
      preferredTime: { required },
      form: {
        field_email: { email, required },
        field_first_name: { required },
        field_last_name: { required },
        field_org_type: { required },
        field_org_name: { required },
        field_org_website: { required, url },
        field_phone: {},
        field_address: {},
        field_hide_screening: {},
        field_country: { required },
        field_state: { required },
        field_city: { required },
        field_zip_code: {},
        field_screening_goal: {},
        field_audience_type: {},
        field_audience_size: {},
        field_screening_format: {},
        field_screening_venue: {},
        field_screening_location: { required },
        field_comments: {}
      }
    }
  }
}
</script>
