<template>
  <view-wrapper class="public-screening" header-text="Host <span class='underline'>public</span> screening with free discussion guide">
    <p class="mb-5">
      We invite you to sign up to host free public screenings of <strong>THE WEST IS BURNING</strong> in your community. With our complimentary viewing guide, we are committed to supporting your discussions and your participation. Thank you for being part of the conversation.
    </p>
    <ul class="mb-5">
      <li><number-label number="1">Register below</number-label></li>
      <li><number-label number="2">Check your inbox for free screening guide</number-label></li>
      <li><number-label number="3">Invite guests</number-label></li>
      <li><number-label number="4">Gather, screen, and discuss</number-label></li>
      <li><number-label number="5">Continue the conversation</number-label></li>
    </ul>

    <h2 class>Register to receive your free screening guide</h2>
    <public-screening-form></public-screening-form>
  </view-wrapper>
</template>

<script>
import PublicScreeningForm from '@/components/_forms/PublicScreeningForm/PublicScreeningForm'

export default {
  name: 'public-screening',

  components: {
    PublicScreeningForm
  }
}
</script>

<style lang="stylus">
  .public-screening
    ul
      margin: 0
      padding: 0
      list-style: none

      .number-label
        margin-bottom: 24px  
</style>
